@import "static/styles/colors";
@import "static/styles/constants";
@import "static/styles/functions";

.termos-de-uso {
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		font-size: $font-size-xl;
		margin-top: $spacing-xxl;
		margin-bottom: $spacing-xxl;
		padding: 0 $spacing-xxl;
		text-align: center;
	}

	.intro {
		margin-top: $spacing-md;
		margin-bottom: $spacing-xxxl;
	}

	.content {
		margin: 0 $spacing-xxl;
		text-align: justify;
		max-width: $max-width-lg;

		p {
			color: $color-interface-dark-deep;
			font-size: $font-size-xs;
			font-weight: $font-weight-medium;
			line-height: $line-height-xs;

			span {
				font-weight: $font-weight-bold;
			}
		}

		p + p {
			margin-top: $spacing-xs;
		}

		ul {
			list-style: inside;

			li {
				color: $color-interface-dark-deep;
				font-size: $font-size-xs;
				font-weight: $font-weight-medium;
				line-height: $line-height-xs;
			}
		}

		ol {
			list-style: upper-roman;

			li {
				color: $color-interface-dark-deep;
				font-size: $font-size-xs;
				font-weight: $font-weight-medium;
				line-height: $line-height-xs;

				span {
					font-weight: $font-weight-bold;
				}
			}

			> li::marker {
				font-weight: $font-weight-bold;
			}

			li + li {
				margin-top: $spacing-xs;
			}
		}

		h2 {
			font-size: $font-size-xs;
			margin-top: $spacing-md;
			margin-bottom: $spacing-md;
		}

		a {
			color: $color-brand-primary-pure;
			text-decoration: underline;
		}
	}
}
